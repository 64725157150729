import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { store } from "../redux/store";
import { Grid } from "@material-ui/core";
import Pagination from "react-js-pagination";
import Footer from "../components/footer/Footer";
import { ROOT, WRAPPER } from "../constants/styles";
import AppNavbar from "../components/navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../components/pageHeader/PageHeader";
import BookCards from "../components/bookCards/BookCards";
import BookLoader from "../components/bookLoader/BookLoader";
import { DIVIDE_BY, DIMENSION } from "../constants/constant";
import Snack from "../components/snackBar/SnackBar";
import _ from "lodash";
import { useParams, withRouter } from "react-router-dom";
import ScrollDialog from "../components/scrollDialog/ScrollDialog";
import Image from "react-image-resizer";

const useStyles = makeStyles(() => ({
  ...ROOT,
  ...WRAPPER,
  margin: {
    margin: "50px 0px",
  },
}));

const AllBooks = (props) => {
  const classes = useStyles();
  const params = useParams();
  const [obj, setObj] = useState(null);
  const [books, setBooks] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [paginateData, setPaginateData] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const getStateFromRedux = () => {
    const { reducer } = store.getState();
    let { books, booksLoading, authors } = reducer;
    const length = books.length;
    setLoading(booksLoading);
    if (length) {
      books = books.map((v) => {
        const find = _.find(authors, (o) => {
          return o.id === v.authorId;
        });
        if (!_.isEmpty(find)) {
          return {
            ...v,
            authorName: find.name,
          };
        }
        return {};
      });
      if (!_.isEmpty(params)) {
        const authorName = params.authorName.replace(/!/g, " ");
        const filter = books.filter((v) => v.authorName === authorName);
        setBooks(filter);
        const chunkArr = _.chunk(filter, DIVIDE_BY);
        setPaginateData([...chunkArr]);
        return;
      }
      setBooks(books);
      const chunkArr = _.chunk(books, DIVIDE_BY);
      setPaginateData([...chunkArr]);
    }
  };

  useEffect(() => {
    getStateFromRedux();
    store.subscribe(getStateFromRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onPageChange = (page) => {
    if (activePage !== page) {
      setActivePage(page);
      window.scrollTo(0, 0);
    }
  };

  const handleOpenSnack = (e) => {
    setOpen(e);
  };

  const handleDialog = (val) => {
    setObj(val);
    setDialog(true);
  };

  const handleDialogClose = () => {
    setObj(null);
    setDialog(false);
  };

  return (
    <div>
      <AppNavbar />
      <PageHeader />
      <Grid container className={clsx(classes.wrapper, classes.margin)}>
        {loading ? (
          [0, 1, 2].map((_, i) => {
            return <BookLoader key={i} />;
          })
        ) : paginateData.length > 0 ? (
          paginateData[activePage - 1].map((v, i) => {
            return (
              <BookCards
                key={i}
                pdf={v.pdf}
                title={v.title}
                image={v.bookImage}
                author={v.authorName}
                handleOpenSnack={handleOpenSnack}
                handleDialog={() => handleDialog(v)}
              />
            );
          })
        ) : (
          <h1 className="no-data">No Record Found</h1>
        )}
      </Grid>
      {dialog && (
        <ScrollDialog
          open={dialog}
          title={"Book"}
          onReadClick={() => setOpen(true)}
          onDownloadClick={() => window.open(obj.pdf)}
          handleDialogClose={() => handleDialogClose()}
        >
          <h4 className="text-left">{obj.title}</h4>
          <p className="text-left" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {obj.authorName}
          </p>
          <br />
          <Image
            width={DIMENSION / 1.5}
            height={DIMENSION / 1.5}
            src={obj.bookImage}
          />
          <br />
          <p
            className="text-left"
            dangerouslySetInnerHTML={{
              __html: obj.description,
            }}
          />
        </ScrollDialog>
      )}
      <Snack
        open={open}
        handleClose={handleClose}
        msg={"This feature will be avaible soon!"}
      />
      {!loading && paginateData.length > 0 && (
        <Pagination
          firstPageText={<i className="fa fa-angle-double-left" />}
          lastPageText={<i className="fa fa-angle-double-right" />}
          prevPageText={<i className="fa fa-angle-left" />}
          nextPageText={<i className="fa fa-angle-right" />}
          itemsCountPerPage={DIVIDE_BY}
          activePage={activePage}
          totalItemsCount={books.length}
          onChange={(page) => onPageChange(page)}
        />
      )}
      <Footer />
    </div>
  );
};

export default withRouter(AllBooks);
