import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

const Snack = ({ msg, open, handleClose }) => {
  const classes = useStyles();

  return (
    <Snackbar
      key={"dialog"}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      message={msg}
      action={
        <React.Fragment>
          <IconButton
            aria-label="close"
            color="secondary"
            className={classes.close}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      }
    />
  )
}

export default Snack;