import React, { useState, useEffect } from 'react'
import '../../../style.css';
import _ from 'lodash';
import Lightbox from 'fslightbox-react';
import { Carousel } from 'react-bootstrap';
import { store } from '../../../redux/store';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

const WebSlider = () => {
  const [toggler, setToggler] = useState(false);
  const [sliders, setSliders] = useState([]);
  const [find, setFind] = useState(null);
  const [sliderGallery, setSliderGallery] = useState([]);

  const getStateFromRedux = () => {
    const { reducer } = store.getState();
    const { sliders, sliderGallery } = reducer;
    setSliders(sliders);
    setSliderGallery(sliderGallery);
  }

  useEffect(() => {
    getStateFromRedux();
    store.subscribe(getStateFromRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="after-navbar">
      {sliders.length > 0 ? (
        <Carousel indicators={false}>
          {sliders.map((v, i) => {
            const find = _.find(sliderGallery, o => o.sliderId === v.id);
            const cond = (find && find.gallery && find.gallery.length > 0);
            return (
              <Carousel.Item key={i}>
                <img
                  src={v.url}
                  alt="Not loaded"
                  className="d-block w-100"
                />
                {(cond) && (
                  <Carousel.Caption>
                    <button
                      className="button carousel-button"
                      onClick={() => {
                        setFind(find);
                        setTimeout(() => {
                          setToggler(!toggler);
                        }, 0)
                      }}
                    >
                      VIEW MORE
                    </button>
                  </Carousel.Caption>
                )}
              </Carousel.Item>
            )
          })}
        </Carousel>
      ) : (
          <ContentLoader
            speed={2}
            height={160}
            primaryColor={'#f0f0f0'}
            secondaryColor={'#e0e0e0'}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="160" />
          </ContentLoader>
        )}

      {find && (
        <Lightbox
          showNav
          onClose={() => {
            setFind(null)
          }}
          types={find.gallery.map(v => {
            if (v.type === "image") {
              return "image"
            };
            return null
          })}
          sources={find.gallery.map(v => {
            if (v.type === "image") {
              return v.url;
            }
            return v.link
          })}
          toggler={toggler}
        />
      )}
    </div>
  )
}

export default withRouter(WebSlider)
