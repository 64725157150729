import { OfficialColor } from "./colors";

export const ROOT = {
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  }
};

export const WRAPPER = {
  wrapper: {
    padding: "0px 10px",
    justifyContent: "center",
  }
};

export const CARD = {
  card: {
    margin: 10,
    width: 380,
    boxShadow: "0 1px 20px 0 rgba(46,61,73,.2)",
  }
};

export const CARD_WRAPPER = {
  cardWrapper: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
  }
};

export const AVATAR = {
  avatar: {
    backgroundColor: OfficialColor,
  },
};

export const CHANGE_COLOR = {
  changeColor: {
    color: OfficialColor,
  },
};

export const ACTIONS = {
  actions: {
    padding: "0px 10px 10px 15px",
  },
};

export const LINK = {
  link: {
    color: OfficialColor,
  }
};