import React from 'react';
import clsx from 'clsx';
import {
  Grid,
  Card,
  IconButton,
  CardActions,
  CardContent,
} from '@material-ui/core';
import {
  GetApp,
  LocalLibrary,
} from '@material-ui/icons';
import {
  ROOT,
  CARD,
  AVATAR,
  ACTIONS,
  WRAPPER,
  CHANGE_COLOR,
  CARD_WRAPPER,
} from '../../constants/styles';
import Image from 'react-image-resizer';
import { makeStyles } from '@material-ui/core/styles';
import { OfficialColor } from '../../constants/colors';
import { DIMENSION } from '../../constants/constant';

const useStyles = makeStyles(theme => ({
  ...ROOT,
  ...CARD,
  ...AVATAR,
  ...WRAPPER,
  ...ACTIONS,
  ...CARD_WRAPPER,
  ...CHANGE_COLOR,
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  changeBackground: {
    color: "#fff",
    backgroundColor: OfficialColor,
    '&:hover': {
      backgroundColor: "#01294c",
    },
  },
  titleWrapper: {
    height: 80,
    marginTop: 10,
    padding: "0px 10px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    textAlign: "center",
  },
  authorWrapper: {
    height: 30,
    padding: "0px 10px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    textAlign: "center",
  }
}));

const BookCards = props => {
  const { image, author, title, handleOpenSnack, pdf, handleDialog } = props;
  const classes = useStyles();

  return (
    <Grid
      item
      lg={4}
      md={4}
      sm={6}
      xs={12}
      className={classes.cardWrapper}
    >
      <Card className={classes.card}>
        <div className="book-img-wrapper">
          <Image
            src={image}
            width={DIMENSION}
            height={DIMENSION}
          />
        </div>
        <CardContent dividers="true">
          <div className={classes.authorWrapper}>
            <p className="book-author">{author}</p>
          </div>
          <div className={classes.titleWrapper}>
            <p className="book-title">{title}</p>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <button
            className="link"
            onClick={() => handleDialog()}
          >
            View...
          </button>
          <IconButton
            aria-label="show pdf"
            onClick={() => handleOpenSnack(true)}
            className={clsx(classes.expand, classes.changeColor)}
          >
            <LocalLibrary />
          </IconButton>
          <IconButton
            aria-label="show more"
            onClick={() => window.open(pdf)}
            className={clsx(classes.expand, classes.changeColor)}
          >
            <GetApp />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default BookCards;