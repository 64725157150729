import React from 'react'
import { withRouter } from 'react-router-dom'

const My404 = () => {
  return (
    <div className="my-404-wrapper">
      <img src={require('../images/New404.png')} alt="not found" className="my-404-image" />
    </div>
  )
}

export default withRouter(My404)
