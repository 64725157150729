import React, { useState, useEffect } from 'react'
import '../../../style.css';
import { Element } from 'react-scroll';
import { Grid } from '@material-ui/core';
import { store } from '../../../redux/store';
import AudioCards from '../../audioCards/AudioCards';
import { makeStyles } from '@material-ui/core/styles';
import AudioLoader from '../../audioLoader/AudioLoader';
import { OfficialColor } from '../../../constants/colors';
import { ROOT, WRAPPER } from '../../../constants/styles';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ScrollDialog from '../../scrollDialog/ScrollDialog';

const useStyles = makeStyles(() => ({
  ...ROOT,
  ...WRAPPER,
  title: {
    marginTop: 50,
    marginBottom: 27,
    textAlign: 'center',
    color: OfficialColor,
  }
}));

const Audios = () => {
  const classes = useStyles();
  let history = useHistory();
  const [obj, setObj] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [audios, setAudios] = useState([]);

  const getStateFromRedux = () => {
    const { reducer } = store.getState();
    const { audios, speechers } = reducer;
    if (audios.length) {
      let slice = audios.slice(0, 3);
      slice = slice.map(v => {
        const find = _.find(speechers, o => {
          return o.id === v.speecherId
        });
        if (!_.isEmpty(find)) {
          return {
            ...v,
            speecherName: find.name,
          }
        }
        return {};
      })
      setAudios(slice);
    }
  }

  useEffect(() => {
    getStateFromRedux();
    store.subscribe(getStateFromRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDialog = val => {
    setObj(val);
    setDialog(true);
  }

  const handleDialogClose = () => {
    setObj(null);
    setDialog(false);
  }

  return (
    <Element name="home-audios" className={classes.root}>
      <h2 className={classes.title}>Audios</h2>
      <Grid container className={classes.wrapper}>
        {audios.length > 0 ? (
          audios.map((v, i) => {
            return (
              <AudioCards
                key={i}
                audio={v.embed}
                title={
                  (v.title)
                }
                subTitle={
                  (v.speecherName)
                }
                description={
                  (v.description) ?
                    (v.description)
                    :
                    "No description provided"
                }
                handleDialog={() => handleDialog(v)}
              />
            )
          })
        ) : (
            [0, 1, 2].map((_, i) => {
              return <AudioLoader key={i} />
            })
          )}
      </Grid>
      {dialog && (
        <ScrollDialog
          open={dialog}
          title={"Audio"}
          onDownloadClick={() => window.open(obj.embed)}
          handleDialogClose={() => handleDialogClose()}
        >
          <h4 className="text-left">{obj.title}</h4>
          <p className="text-left" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {obj.speecherName}
          </p>
          <br />
          <div className="rodal-content">
            <audio
              controls
              src={obj.embed}
            />
          </div>
          <br />
          <br />
          <p className="text-left" dangerouslySetInnerHTML={{
            __html: obj.description
          }} />
        </ScrollDialog>
      )}
      <button
        disabled={audios.length < 1}
        className="button-semi-transparent"
        onClick={() => history.push('/audios')}
        style={{ marginTop: 27, background: OfficialColor, borderColor: OfficialColor, color: "#fff" }}
      >
        MORE AUDIOS
      </button>
    </Element>
  )
}

export default Audios;