import React from 'react';
import { Grid } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

const EventLoader = () => {
  return (
    <Grid
      item
      lg={3}
      md={4}
      sm={6}
      xs={12}
    >
      <ContentLoader
        speed={2}
        height={360}
        primaryColor={'#f0f0f0'}
        secondaryColor={'#e0e0e0'}
      >
        <rect x="10" y="0" rx="0" ry="0" width="350" height="600" />
      </ContentLoader>
    </Grid>
  )
}

export default EventLoader;