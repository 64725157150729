import React, { useState, useRef, useEffect } from 'react';
import '../../style.css';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Close, HeadsetMic, MenuBook, EventNote } from '@material-ui/icons';
import {
  Grid,
  List,
  Paper,
  Avatar,
  ListItem,
  InputBase,
  IconButton,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import Image from 'react-image-resizer';
import { store } from '../../redux/store';
import Snack from '../snackBar/SnackBar';
import { OfficialColor } from '../../constants/colors';
import ScrollDialog from '../scrollDialog/ScrollDialog';
import { withRouter, useHistory } from 'react-router-dom';
import { DIMENSION } from '../../constants/constant';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: 'center',
    flexDirection: "column",
    justifyContent: 'center',
  },
  wrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  margin: {
    margin: "70px 0px 0px 0px",
  },
  inputRoot: {
    width: "90%",
    borderRadius: 0,
    display: 'flex',
    padding: '2px 4px',
    alignItems: 'center',
    borderBottom: "1px solid #ddd",
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  listRoot: {
    padding: 0,
    width: '90%',
    overflow: "auto",
    maxHeight: "75vh",
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    borderBottom: "1px solid #ededed",
  },
  avatar: {
    backgroundColor: OfficialColor
  }
}));

const Search = () => {
  let ref = useRef(null);
  const history = useHistory();
  const classes = useStyles();
  const [txt, setTxt] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false)
  const [overlay, setOverlay] = useState("");
  const [bookObj, setBookObj] = useState(null);
  const [audioObj, setAudioObj] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [bookDialog, setBookDialog] = useState(false);
  const [audioDialog, setAudioDialog] = useState(false);

  useEffect(() => {
    getStateFromRedux();
    store.subscribe(getStateFromRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStateFromRedux = () => {
    const { reducer } = store.getState();
    const { events, audios, books, booksLoading, audiosLoading, eventsLoading } = reducer;
    if (
      !booksLoading &&
      !audiosLoading &&
      !eventsLoading
    ) {
      setData([...books, ...events, ...audios]);
    }
  }

  const handleOverlay = () => {
    if (overlay === "") {
      ref.style.display = "block";
      document.body.style.position = "fixed";
      setOverlay("block")
    }
    else {
      ref.style.display = "none";
      document.body.style.position = "unset";
      setOverlay("")
    }
  }

  const search = v => {
    setTxt(v);
    if (v && data.length > 0) {
      const f = data.filter(obj => {
        return obj.title && (
          obj.title.toLowerCase().includes(v.toLowerCase())
        )
      });
      setFilterData([...f]);
      return;
    };
    setFilterData([]);
    return;
  }

  const handleClose = () => {
    setOpen(false);
  }

  const onSearchItemClick = o => {
    if (o.searchType === "audio") {
      setAudioObj({ ...o });
      setAudioDialog(true);
      return;
    }
    if (o.searchType === "book") {
      setBookObj({ ...o });
      setBookDialog(true);
      return;
    }
    if (o.searchType === "event") {
      handleOverlay();
      history.push(`/events/${o.title.replace(/ /g, '!')}`)
      return;
    }
  }

  const handleDialogClose = (obj) => {
    if (obj.searchType === "audio") {
      setAudioObj(null);
      setAudioDialog(false);
      return;
    }
    if (obj.searchType === "book") {
      setBookObj(null);
      setBookDialog(false);
      return;
    }
  }

  return (
    <>
      <div className="search_overlay" ref={r => ref = r}>
        <img
          alt="Not Found"
          onClick={handleOverlay}
          className="search_cancel_icon"
          src={require('../../images/cancel.png')}
        />

        {audioDialog && (
          <ScrollDialog
            title={"Audio"}
            open={audioDialog}
            onDownloadClick={() => window.open(audioObj.embed)}
            handleDialogClose={() => handleDialogClose(audioObj)}
          >
            <h4 className="text-left">{audioObj.title}</h4>
            <p className="text-left" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
              {audioObj.speecherName}
            </p>
            <br />
            <div className="rodal-content">
              <audio
                controls
                src={audioObj.embed}
              />
            </div>
            <br />
            <br />
            <p className="text-left" dangerouslySetInnerHTML={{
              __html: audioObj.description
            }} />
          </ScrollDialog>
        )}

        {bookDialog && (
          <ScrollDialog
            open={bookDialog}
            title={"Book"}
            onReadClick={() => setOpen(true)}
            onDownloadClick={() => window.open(bookObj.pdf)}
            handleDialogClose={() => handleDialogClose(bookObj)}
          >
            <h4 className="text-left">{bookObj.title}</h4>
            <p className="text-left" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
              {bookObj.authorName}
            </p>
            <br />
            <Image
              width={DIMENSION / 1.5}
              height={DIMENSION / 1.5}
              src={bookObj.bookImage}
            />
            <br />
            <p className="text-left" dangerouslySetInnerHTML={{
              __html: bookObj.description
            }} />
          </ScrollDialog>
        )}
        <Snack
          open={open}
          handleClose={handleClose}
          msg={"This feature will be avaible soon!"}
        />

        <Grid container className={clsx(classes.root, classes.margin)}>
          <Grid
            item
            md={6}
            xs={12}
            className={classes.wrapper}
          >
            <Paper component="form" className={classes.inputRoot}>
              <IconButton
                aria-label="search"
                className={classes.iconButton}
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                value={txt}
                className={classes.input}
                placeholder="Search audios, books..."
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={({ target }) => search(target.value)}
              />
              {txt && (
                <IconButton
                  color="primary"
                  aria-label="close"
                  onClick={() => {
                    setTxt("");
                    setFilterData([])
                  }}
                  className={classes.iconButton}
                >
                  <Close />
                </IconButton>
              )}
            </Paper>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            className={classes.wrapper}
          >
            <List className={classes.listRoot}>
              {filterData.length > 0 ? (
                filterData.map((v, i) => {
                  return (
                    <ListItem
                      button
                      key={i}
                      className={classes.list}
                      onClick={() => onSearchItemClick(v)}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          {v.searchType === "audio" && <HeadsetMic />}
                          {v.searchType === "book" && <MenuBook />}
                          {v.searchType === "event" && <EventNote />}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={v.title}
                        secondary={v.searchType}
                      // secondary={(
                      //   <span className="search-desc" dangerouslySetInnerHTML={{
                      //     __html: v.description && (
                      //       v.description.length > 100 ?
                      //         `${v.description.substring(0, 100)}...` :
                      //         v.description
                      //     )
                      //   }} />
                      // )}
                      />
                    </ListItem>
                  )
                })
              ) : (
                  txt && (
                    <h4 className="no-search-text">No Search found "{txt}"</h4>
                  )
                )}
            </List>
          </Grid>
        </Grid>
      </div>
      <span className="nav-link-custom" onClick={handleOverlay}>
        <SearchIcon /> SEARCH
      </span>
    </>
  )
}

export default withRouter(Search);