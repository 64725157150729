import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { store } from "../redux/store";
import { Grid } from "@material-ui/core";
import Pagination from "react-js-pagination";
import Footer from "../components/footer/Footer";
import { ROOT, WRAPPER } from "../constants/styles";
import AppNavbar from "../components/navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../components/pageHeader/PageHeader";
import EventCards from "../components/eventCards/EventCards";
import EventLoader from "../components/eventLoader/EventLoader";
import { DIVIDE_BY } from "../constants/constant";
import _ from "lodash";
import { withRouter, useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  ...ROOT,
  ...WRAPPER,
  margin: {
    margin: "50px 0px",
  },
}));

const AllEvents = () => {
  const classes = useStyles();
  const history = useHistory();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [paginateData, setPaginateData] = useState([]);

  const getStateFromRedux = () => {
    const { reducer } = store.getState();
    const { events, eventsLoading } = reducer;
    const length = events.length;
    setLoading(eventsLoading);
    if (length) {
      setEvents(events);
      const chunkArr = _.chunk(events, DIVIDE_BY);
      setPaginateData([...chunkArr]);
    }
  };

  useEffect(() => {
    getStateFromRedux();
    store.subscribe(getStateFromRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    if (activePage !== page) {
      setActivePage(page);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      <AppNavbar />
      <PageHeader />
      <Grid container className={clsx(classes.wrapper, classes.margin)}>
        {loading ? (
          [0, 1, 2, 3].map((_, i) => {
            return <EventLoader key={i} />;
          })
        ) : paginateData.length > 0 ? (
          paginateData[activePage - 1].map((v, i) => {
            return (
              <EventCards
                key={i}
                url={v.url}
                title={v.title}
                subTitle={v.type}
                onClick={() =>
                  history.push(`/events/${v.title.replace(/ /g, "!")}`)
                }
              />
            );
          })
        ) : (
          <h1 className="no-data">No Record Found</h1>
        )}
      </Grid>
      {!loading && paginateData.length > 0 && (
        <Pagination
          firstPageText={<i className="fa fa-angle-double-left" />}
          lastPageText={<i className="fa fa-angle-double-right" />}
          prevPageText={<i className="fa fa-angle-left" />}
          nextPageText={<i className="fa fa-angle-right" />}
          itemsCountPerPage={DIVIDE_BY}
          activePage={activePage}
          totalItemsCount={events.length}
          onChange={(page) => onPageChange(page)}
        />
      )}
      <Footer />
    </div>
  );
};

export default withRouter(AllEvents);
