import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Card,
  Avatar,
  CardHeader,
  Typography,
  IconButton,
  CardActions,
  CardContent,
} from '@material-ui/core';
import {
  GetApp,
  HeadsetMic,
} from '@material-ui/icons';
import {
  ROOT,
  LINK,
  CARD,
  AVATAR,
  ACTIONS,
  CARD_WRAPPER,
  CHANGE_COLOR,
} from '../../constants/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  ...ROOT,
  ...CARD,
  ...LINK,
  ...AVATAR,
  ...ACTIONS,
  ...CARD_WRAPPER,
  ...CHANGE_COLOR,
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  desc: {
    // height: 40,
    // width: "100%",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    width: "100%",
    overflow: "hidden",
  },
  ellipse: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  contentWrapper: {
    height: 40,
    width: "100%",
    background: "#fff",
    overflow: "hidden",
  },
}));

const AudioCards = props => {
  const classes = useStyles();
  let headerParentRef = useRef(null);

  useEffect(() => {
    if (
      headerParentRef &&
      headerParentRef.parentElement &&
      headerParentRef.parentElement.style
    ) {
      headerParentRef.parentElement.style.width = 0;
    }
  }, [headerParentRef])

  const { audio, title, subTitle, description, handleDialog } = props;
  return (
    <Grid
      item
      lg={4}
      md={4}
      sm={6}
      xs={12}
      className={classes.cardWrapper}
    >
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              <HeadsetMic />
            </Avatar>
          }
          title={title}
          titleTypographyProps={{
            className: classes.ellipse,
            ref: ref => headerParentRef = ref,
          }}
          subheader={subTitle}
          subheaderTypographyProps={{
            className: classes.ellipse,
          }}
          style={{ cursor: "pointer" }}
        />
        <CardContent className="audios-card-audio">
          <audio
            controls
            src={audio}
          />
        </CardContent>
        <CardContent>
          <div className={classes.contentWrapper}>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.desc}>
              {description}
            </Typography>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <button
            className="link"
            onClick={() => handleDialog()}
          >
            View...
          </button>
          <IconButton
            aria-label="download"
            onClick={() => window.open(audio)}
            className={clsx(classes.expand, classes.changeColor)}
          >
            <GetApp />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default AudioCards;