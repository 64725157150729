import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import Home from "../screens/Home";
import My404 from "../screens/My404";
import AllBooks from "../screens/AllBooks";
import AllAudios from "../screens/AllAudios";
import AllEvents from "../screens/AllEvents";
import ScrollToTop from "../constants/functions";
import EventDetails from "../screens/EventDetails";
import PersonalityDetails from "../screens/PersonalityDetails";

const history = createBrowserHistory();

const Routes = () => {
  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/books" component={AllBooks} />
          <Route path="/books/:authorName" component={AllBooks} />
          <Route exact path="/audios" component={AllAudios} />
          {
            /**
              * If you wanna pass one parameter, no need to pass exact prop in Route component.
              * If you wanna pass multilple parameters, don't pass exact prop in last parameter of Route component.
            **/
          }
          <Route path="/audios/:speecherName" component={AllAudios} />
          <Route exact path="/events" component={AllEvents} />
          <Route path="/events/:eventName" component={EventDetails} />
          <Route exact path="/personalities/:personalityName" component={PersonalityDetails} />
          <Route exact path='*' component={My404} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

export default Routes
