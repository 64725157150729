import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

export const ABBR_NUM = number => {
  let abbrev = ["K", "M", "B", "T"];
  for (var i = abbrev.length - 1; i >= 0; i--) {
    var size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.abs(number / size);
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }
      var round = Math.floor(number);
      var addPlus = round < number ? "+" : "";
      number = round + abbrev[i] + addPlus;
      break;
    }
  }
  return number;
};

export const ValidateEmail = email => {
  //eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};


const ScrollToTop = ({ children, history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (<Fragment>{children}</Fragment>);
}

export default withRouter(ScrollToTop);