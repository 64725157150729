import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import { store } from '../redux/store';
import { Grid } from '@material-ui/core';
import Pagination from "react-js-pagination";
import Footer from '../components/footer/Footer';
import { ROOT, WRAPPER } from '../constants/styles';
import AppNavbar from '../components/navbar/Navbar';
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../components/pageHeader/PageHeader';
import AudioCards from '../components/audioCards/AudioCards';
import AudioLoader from '../components/audioLoader/AudioLoader';
import { DIVIDE_BY } from '../constants/constant';
import _ from 'lodash';
import { useParams, withRouter } from 'react-router-dom'
import ScrollDialog from '../components/scrollDialog/ScrollDialog';

const useStyles = makeStyles(() => ({
  ...ROOT,
  ...WRAPPER,
  margin: {
    margin: "50px 0px",
  }
}));

const AllAudios = props => {
  const classes = useStyles();
  const params = useParams();
  const [audios, setAudios] = useState([]);
  const [obj, setObj] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [paginateData, setPaginateData] = useState([]);

  const getStateFromRedux = () => {
    const { reducer } = store.getState();
    let { audios, audiosLoading, speechers } = reducer;
    const length = audios.length;
    setLoading(audiosLoading);
    if (length) {
      audios = audios.map(v => {
        const find = _.find(speechers, o => {
          return o.id === v.speecherId
        });
        if (!_.isEmpty(find)) {
          return {
            ...v,
            speecherName: find.name,
          }
        }
        return {};
      })
      if (!_.isEmpty(params)) {
        const speecherName = params.speecherName.replace(/!/g, " ");
        const filter = audios.filter(v => v.speecherName === speecherName);
        setAudios(filter);
        const chunkArr = _.chunk(filter, DIVIDE_BY)
        setPaginateData([...chunkArr])
        return;
      }
      setAudios(audios);
      const chunkArr = _.chunk(audios, DIVIDE_BY)
      setPaginateData([...chunkArr])
    }
  }

  useEffect(() => {
    getStateFromRedux();
    store.subscribe(getStateFromRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const onPageChange = page => {
    if (activePage !== page) {
      setActivePage(page);
      window.scrollTo(0, 0)
    }
  }

  const handleDialog = val => {
    setObj(val);
    setDialog(true);
  }

  const handleDialogClose = () => {
    setObj(null);
    setDialog(false);
  }

  return (
    <div>
      <AppNavbar />
      <PageHeader />
      <Grid container className={clsx(classes.wrapper, classes.margin)}>
        {loading ?
          [0, 1, 2].map((_, i) => {
            return <AudioLoader key={i} />
          })
          :
          paginateData.length > 0 ? (
            paginateData[activePage - 1].map((v, i) => {
              return (
                <AudioCards
                  key={i}
                  audio={v.embed}
                  title={
                    (v.title)
                  }
                  subTitle={
                    (v.speecherName)
                  }
                  description={
                    (v.description) ?
                      (v.description)
                      :
                      "No description provided"
                  }
                  handleDialog={() => handleDialog(v)}
                />
              )
            })
          ) : (
              <h1 className="no-data">No Record Found</h1>
            )}
      </Grid>
      {dialog && (
        <ScrollDialog
          open={dialog}
          title={"Audio"}
          onDownloadClick={() => window.open(obj.embed)}
          handleDialogClose={() => handleDialogClose()}
        >
          <h4 className="text-left">{obj.title}</h4>
          <p className="text-left" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {obj.speecherName}
          </p>
          <br />
          <div className="rodal-content">
            <audio
              controls
              src={obj.embed}
            />
          </div>
          <br />
          <br />
          <p className="text-left" dangerouslySetInnerHTML={{
            __html: obj.description
          }} />
        </ScrollDialog>
      )}
      {!loading && paginateData.length > 0 && (
        <Pagination
          firstPageText={<i className="fa fa-angle-double-left" />}
          lastPageText={<i className="fa fa-angle-double-right" />}
          prevPageText={<i className="fa fa-angle-left" />}
          nextPageText={<i className="fa fa-angle-right" />}
          itemsCountPerPage={DIVIDE_BY}
          activePage={activePage}
          totalItemsCount={audios.length}
          onChange={page => onPageChange(page)}
        />
      )}
      <Footer />
    </div>
  )
}

export default withRouter(AllAudios);
