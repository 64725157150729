import React, { useEffect } from 'react';
import Routes from './routes';
import { KeyboardArrowUp } from '@material-ui/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getBooks,
  getAudios,
  getEvents,
  getAuthors,
  getSliders,
  getSpeechers,
  getPersonalities,
  getSliderGallery,
} from "./redux/actions";
import { store } from "./redux/store";
import { animateScroll } from 'react-scroll'
import $ from 'jquery';

const App = () => {

  useEffect(() => {
    $(document).scroll(function () {
      var y = $(this).scrollTop();
      if (y > 800) {
        $('.scroll-up').fadeIn();
      } else {
        $('.scroll-up').fadeOut();
      }
    });
    store.dispatch(getSliders());
    store.dispatch(getSliderGallery());
    store.dispatch(getSpeechers());
    store.dispatch(getAudios());
    store.dispatch(getAuthors());
    store.dispatch(getBooks());
    store.dispatch(getEvents());
    store.dispatch(getPersonalities());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes />
      <button
        className="scroll-up"
        style={{ display: 'none' }}
        onClick={() => animateScroll.scrollToTop()}>
        <KeyboardArrowUp />
      </button>
    </>
  );
}

export default App;
