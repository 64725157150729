import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import { store } from '../redux/store';
import { Grid } from '@material-ui/core';
import Footer from '../components/footer/Footer';
import { ROOT, WRAPPER } from '../constants/styles';
import AppNavbar from '../components/navbar/Navbar';
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../components/pageHeader/PageHeader';
import _ from 'lodash';
import { useParams, withRouter } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  ...ROOT,
  ...WRAPPER,
  margin: {
    margin: "50px 0px",
  }
}));

const EventDetails = () => {
  const classes = useStyles();
  const params = useParams();
  const [event, setEvent] = useState({});

  const getStateFromRedux = () => {
    const { reducer } = store.getState();
    const { events } = reducer;
    const length = events.length;
    if (length) {
      if (!_.isEmpty(params)) {
        const eventName = params.eventName.replace(/!/g, " ");
        const filter = _.find(events, v => v.title === eventName);
        setEvent(filter);
        return;
      }
    }
  }

  useEffect(() => {
    getStateFromRedux();
    store.subscribe(getStateFromRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <AppNavbar />
      <PageHeader />
      <Grid container className={clsx(classes.wrapper, classes.margin)}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <div className="news_item">
            <h2 className="text-center">{event && event.title}</h2>
            <div className="news_desc text-left">
              <p dangerouslySetInnerHTML={{
                __html: event && event.description
              }}></p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </div>
  )
}

export default withRouter(EventDetails)
