import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { store } from "./redux/store";

const Container = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(<Container />, document.getElementById('root'));
serviceWorker.unregister();
