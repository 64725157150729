import React, { useRef, useEffect, useState } from 'react'
import '../../style.css';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { store } from '../../redux/store';
import { Link as ScrollLink, Element } from 'react-scroll'
import Search from '../search/Search';

const SubItem = ({ url, text }) => {
  return (
    <li className="dropdown-list">
      <Link to={url} className="dropdown-link">{text}</Link>
    </li>
  )
}

const ScrollableSubLink = ({ id, text, offset, onClick }) => {
  return (
    <li className="dropdown-list">
      <ScrollLink
        spy={true}
        smooth={true}
        offset={offset}
        duration={500}
        to={id}
        onClick={onClick}
        className="dropdown-link"
      >
        {text}
      </ScrollLink>
    </li>
  )
}

const ScrollableLink = ({ text, id, offset, onClick }) => {
  return (
    <li className="nav-list">
      <ScrollLink
        spy={true}
        smooth={true}
        offset={offset}
        duration={500}
        to={id}
        onClick={onClick}
        className="nav-link-custom"
      >
        {text}
      </ScrollLink>
    </li>
  )
}

const AppNavbar = props => {
  const [authors, setAuthors] = useState([]);
  const [pathName, setPathName] = useState("/");
  const [speechers, setSpeechers] = useState([]);
  const [personalities, setPersonalities] = useState([]);

  let toggleMenuIconRef = useRef(null);
  let toggleMenuRef = useRef(null);

  const toggleMenuIcon = () => {
    toggleMenuIconRef.current.classList.toggle("change");
    toggleMenuRef.current.classList.toggle("show");
  };

  const getStateFromRedux = () => {
    const { reducer } = store.getState();
    const { authors, speechers, personalities } = reducer;
    authors.length && setAuthors(authors);
    speechers.length && setSpeechers(speechers);
    personalities.length && setPersonalities(personalities);
  }

  useEffect(() => {
    const { location: { pathname } } = props;
    setPathName(pathname);
    // console.log("pathname ===> ", pathname);
    getStateFromRedux();
    store.subscribe(getStateFromRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <Element name="home">
      <div className="navbar-wrapper" id="fixed">
        <div className="navbar-container">
          <div className="logo-wrapper">
            <img src={require('../../images/logo.png')} alt="logo" className="logo" />
            <div
              ref={toggleMenuIconRef}
              className="menu-button"
              onClick={toggleMenuIcon}
            >
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
          <div className="navbar">
            <ul ref={toggleMenuRef} id="navbar" className="nav">
              {pathName === "/" ? (
                <ScrollableLink
                  id="home"
                  offset={0}
                  text="HOME"
                  onClick={toggleMenuIcon}
                />
              ) : (
                  <li className="nav-list">
                    <Link to={"/"} className="nav-link-custom">HOME</Link>
                  </li>
                )}
              <li className="nav-list">
                <span className="nav-link-custom">
                  AUDIOS
                <FontAwesomeIcon
                    icon={faAngleDown}
                    transform="shrink-2"
                    style={{ marginLeft: 3 }}
                  />
                </span>
                <ul
                  onClick={toggleMenuIcon}
                  className="dropdown-menu-custom animated fadeIn delay-2s"
                >
                  {pathName === "/" ? (
                    <ScrollableSubLink
                      id="home-audios"
                      text={"Latest"}
                      offset={-50}
                      onClick={toggleMenuIcon}
                    />
                  ) : (
                      <SubItem url={"/"} text="Latest" />
                    )}
                  <div className="dropdown-divider"></div>
                  {speechers.map((v, i) => {
                    return (
                      <SubItem key={i} url={`/audios/${v.name.replace(/ /g, '!')}`} text={v.name} />
                    )
                  })}
                  <div className="dropdown-divider"></div>
                  <SubItem url={"/audios"} text={"All Audios"} />
                </ul>
              </li>
              <li className="nav-list">
                <span className="nav-link-custom">
                  BOOKS <FontAwesomeIcon icon={faAngleDown} transform="shrink-2" style={{ marginLeft: 3 }} />
                </span>
                <ul
                  onClick={toggleMenuIcon}
                  className="dropdown-menu-custom animated fadeIn delay-2s"
                >
                  {pathName === "/" ? (
                    <ScrollableSubLink
                      id="home-books"
                      text={"Latest"}
                      offset={-50}
                      onClick={toggleMenuIcon}
                    />
                  ) : (
                      <SubItem url={"/"} text="Latest" />
                    )}
                  <div className="dropdown-divider"></div>
                  {authors.map((v, i) => {
                    return (
                      <SubItem key={i} url={`/books/${v.name.replace(/ /g, '!')}`} text={v.name} />
                    )
                  })}
                  <div className="dropdown-divider"></div>
                  <SubItem url={"/books"} text={"All Books"} />
                </ul>
              </li>
              <li className="nav-list">
                <Link to={"/events"} className="nav-link-custom">EVENTS</Link>
              </li>
              {/* <li className="nav-list">
                <Link to={"/"} className="nav-link-custom">IFTA</Link>
              </li>
              <li className="nav-list">
                <Link to={"/"} className="nav-link-custom">TAWEEZAT</Link>
              </li> */}
              <li className="nav-list">
                <span className="nav-link-custom">
                  PERSONALITIES
                  <FontAwesomeIcon icon={faAngleDown} transform="shrink-2" style={{ marginLeft: 3 }} />
                </span>
                {personalities.length > 0 && (
                  <ul
                    onClick={toggleMenuIcon}
                    className="dropdown-menu-custom animated fadeIn delay-2s"
                  >
                    {personalities.map((v, i) => {
                      return (
                        <SubItem key={i} url={`/personalities/${v.name.replace(/ /g, '!')}`} text={v.name} />
                      )
                    })}
                  </ul>
                )}
              </li>
              {/* <li className="nav-list">
              <Link to={"/"} className="nav-link-custom">ABOUT US</Link>
            </li> */}
              {pathName === "/" ? (
                <ScrollableLink
                  id="home-contact"
                  text="CONTACT US"
                  offset={-50}
                  onClick={toggleMenuIcon}
                />
              ) : (
                  <li className="nav-list">
                    <Link to={"/"} className="nav-link-custom">CONTACT US</Link>
                  </li>
                )}
              <li className="nav-list">
                <Search />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Element>
  )
}

export default withRouter(AppNavbar);