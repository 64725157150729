import React from 'react';
import { Grid } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

const BookLoader = () => {
  return (
    <Grid
      item
      lg={4}
      md={4}
      sm={6}
      xs={12}
    >
      <ContentLoader
        speed={2}
        height={360}
        primaryColor={'#f0f0f0'}
        secondaryColor={'#e0e0e0'}
      >
        <rect x="10" y="0" rx="0" ry="0" width="350" height="250" />
        <rect x="10" y="270" rx="0" ry="0" width="350" height="13" />
        <rect x="10" y="290" rx="0" ry="0" width="350" height="13" />
        <rect x="10" y="310" rx="0" ry="0" width="350" height="13" />
        <rect x="10" y="330" rx="0" ry="0" width="350" height="13" />
        <rect x="10" y="350" rx="0" ry="0" width="350" height="13" />
        <rect x="10" y="370" rx="0" ry="0" width="350" height="13" />
      </ContentLoader>
    </Grid>
  )
}

export default BookLoader;