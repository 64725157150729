import React from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { useParams, useLocation, Link } from 'react-router-dom'

const PageHeader = () => {
  const breadcrumb = [{
    url: "/",
    name: "Home",
    active: false,
  }];
  const params = useParams();
  const { pathname } = useLocation();
  const routeName = pathname.split('/')[1];
  let value = [];

  if (!_.isEmpty(params)) {
    value = Object.values(params);
    breadcrumb.push({
      active: routeName !== "personalities" ? false : true,
      name: routeName !== "personalities" ? routeName : value[0].replace(/!/g, " "),
      url: routeName !== "personalities" ? `/${routeName}` : "",
    });
    if (routeName !== "personalities") {
      value.map((v, i) => {
        let cond = (i + 1) === value.length;
        return breadcrumb.push({
          active: cond,
          name: v.replace(/!/g, " "),
          url: cond ? "" : `/${routeName}/${value.slice(0, i + 1).join('/')}`,
        });
      })
    }
  }
  else {
    breadcrumb.push({
      url: "",
      active: true,
      name: routeName,
    });
  }

  return (
    <div className="page-header after-navbar center-block">
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <div className="page-titles text-center">
            <h1 className="whitecolor font-light page-heading">
              {(routeName === "personalities") ? "Personality" :
                (routeName === "events" && value.length > 0) ? "Event" :
                  (routeName === "events" && value.length <= 0) ? "Events" :
                    routeName
              }
            </h1>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
              <ol className="breadcrumb">
                {breadcrumb.map((v, i) => {
                  return (
                    <li
                      key={i}
                      className={`breadcrumb-item ${v.active && "active"}`}
                    >
                      {v.active ? v.name : (
                        <Link to={v.url}>
                          {v.name}
                        </Link>
                      )}
                    </li>
                  )
                })}
              </ol>
            </nav>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default PageHeader;