import React from 'react'

const Footer = () => {
  return (
    <footer className="padding-half">
      <p className="copyrights">
        Copyright &copy; {new Date().getFullYear()} <a href="https://www.sadiqulislam.net/">Madarsa Sadiq ul Islam</a> | All Rights Reserved
      </p>
    </footer>
  )
}

export default Footer;
