import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CHANGE_COLOR } from '../../constants/styles';
import { Close, GetApp, LocalLibrary } from '@material-ui/icons';
import Rodal from "rodal";
import "rodal/lib/rodal.css";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ...CHANGE_COLOR,
}));

const ScrollDialog = props => {
  const classes = useStyles();
  const { open, handleDialogClose, title, children, onDownloadClick, onReadClick } = props;
  return (
    title === "Audio" ? (
      <Rodal
        visible={open}
        width={window.screen.width < 450 ? 310 : 440}
        onClose={() => handleDialogClose()}
      >
        <p className="modal-title" id="scroll-dialog-title">
          {title}
        </p>
        <hr />
        {children}
        <hr />
        <div className="rodal-actions">
          <IconButton
            aria-label="download"
            className={classes.changeColor}
            onClick={() => onDownloadClick()}
          >
            <GetApp />
          </IconButton>
        </div>
      </Rodal>
    )
      :
      (
        <Dialog
          fullWidth
          open={open}
          scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          onClose={() => handleDialogClose()}
        >
          <DialogTitle id="scroll-dialog-title">
            {title}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => handleDialogClose()}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={true}>
            {children}
          </DialogContent>
          <DialogActions >
            <IconButton
              aria-label="read"
              onClick={() => onReadClick()}
              className={classes.changeColor}
            >
              <LocalLibrary />
            </IconButton>
            <IconButton
              aria-label="download"
              className={classes.changeColor}
              onClick={() => onDownloadClick()}
            >
              <GetApp />
            </IconButton>
          </DialogActions>
        </Dialog>
      )
  );
}
export default ScrollDialog;