import React, { lazy, Suspense } from 'react'
import AppNavbar from '../components/navbar/Navbar';
import Slider from '../components/home/slider/Slider';
import Audios from '../components/home/audios/Audios';

const Footer = lazy(() => import('../components/footer/Footer'));
const Books = lazy(() => import('../components/home/books/Books'));
const Contact = lazy(() => import('../components/home/contact/Contact'));
const WeOffers = lazy(() => import('../components/home/weOffers/WeOffers'));
const TextSection = lazy(() => import('../components/home/textSection/TextSection'));

const Home = () => {
  return (
    <div>
      <AppNavbar />
      <Slider />
      <Audios />
      <Suspense fallback={<h1 className="loader">Still Loading…</h1>}>
        <WeOffers />
        <Books />
        <TextSection />
        <Contact />
        <Footer />
      </Suspense>
    </div>
  )
}

export default Home
