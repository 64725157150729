import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    margin: 10,
    width: 280,
    borderRadius: 0,
    boxShadow: "0 1px 20px 0 rgba(46,61,73,.2)",
  },
  cardWrapper: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


const EventCards = props => {
  const classes = useStyles();
  const { url, title, subTitle, onClick } = props
  return (
    <Grid
      item
      lg={3}
      md={4}
      sm={6}
      xs={12}
      className={classes.cardWrapper}
    >
      <Card className={classes.card}>
        <div className="event-card" style={{
          backgroundImage: `url('${url}')`
        }}>
          <div className="event-bg">
            <h4 style={{ color: "#fff" }}>{title}</h4>
            <p>{subTitle}</p>
            <button
              style={{
                width: 180,
              }}
              className="button-semi-white"
              onClick={onClick}
            >
              LEARN MORE
            </button>
          </div>
        </div>
      </Card>
    </Grid>
  )
}

export default EventCards;