const INITIAL_STATE = {
    books: [],
    audios: [],
    events: [],
    sliders: [],
    authors: [],
    speechers: [],
    sliderGallery: [],
    personalities: [],
    booksLoading: true,
    audiosLoading: true,
    eventsLoading: true,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "IS_LOGIN": {
            return { ...state, isLogin: action.isLogin };
        }
        case "LOADER": {
            return { ...state, loader: action.loader };
        }
        case "GET_BOOK_DETAILS": {
            return { ...state, bookDetails: action.bookDetails };
        }
        case "GET_AUDIO_DETAILS": {
            return { ...state, audioDetails: action.audioDetails };
        }
        case "GET_EVENT_DETAILS": {
            return { ...state, eventDetails: action.eventDetails };
        }
        case "GET_PERSONALITY_DETAILS": {
            return { ...state, personalityDetails: action.personalityDetails };
        }
        case "GET_AUTHOR": {
            return { ...state, authorName: action.authorName };
        }
        case "GET_SPEECHER": {
            return { ...state, speecherName: action.speecherName };
        }
        case "AUDIOS": {
            return { ...state, audios: action.audios };
        }
        case "AUDIOS_LOADING": {
            return { ...state, audiosLoading: action.audiosLoading };
        }
        case "BOOKS": {
            return { ...state, books: action.books };
        }
        case "BOOKS_LOADING": {
            return { ...state, booksLoading: action.booksLoading };
        }
        case "SLIDERS": {
            return { ...state, sliders: action.sliders };
        }
        case "EVENTS": {
            return { ...state, events: action.events };
        }
        case "EVENTS_LOADING": {
            return { ...state, eventsLoading: action.eventsLoading };
        }
        case "SPEECHERS": {
            return { ...state, speechers: action.speechers };
        }
        case "AUTHORS": {
            return { ...state, authors: action.authors };
        }
        case "PERSONALITIES": {
            return { ...state, personalities: action.personalities };
        }
        case "SLIDER_GALLERY": {
            return { ...state, sliderGallery: action.sliderGallery };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
