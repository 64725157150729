import { FIRESTORE } from '../../constants/firebase';

export const loader = loader => {
  return {
    type: "LOADER",
    loader
  };
};

export const speecher = speecherName => {
  return {
    type: "GET_SPEECHER",
    speecherName
  };
};

export const author = authorName => {
  return {
    type: "GET_AUTHOR",
    authorName
  };
};

export const isLogin = flag => {
  return {
    type: "IS_LOGIN",
    isLogin: flag
  };
};

// Database managements

export const getAuthors = () => {
  return (dispatch) => {
    FIRESTORE.collection("authors")
      .orderBy("timeStamp", "asc")
      .onSnapshot(snap => {
        let arr = [];
        snap.forEach(doc => {
          var obj = doc.data();
          obj.id = doc.id;
          arr.push(obj);
        });
        dispatch(authors(arr));
      });
  }
}

export const authors = authors => {
  return {
    type: "AUTHORS",
    authors
  };
};


export const getSpeechers = () => {
  return (dispatch) => {
    FIRESTORE.collection("speechers")
      .orderBy("timeStamp", "asc")
      .onSnapshot(snap => {
        let arr = [];
        snap.forEach(doc => {
          var obj = doc.data();
          obj.id = doc.id;
          arr.push(obj);
        });
        dispatch(speechers(arr));
      });
  }
}


export const speechers = speechers => {
  return {
    type: "SPEECHERS",
    speechers
  };
};


export const getAudios = () => {
  return (dispatch) => {
    FIRESTORE.collection("bayans")
      .orderBy("timeStamp", "desc")
      .onSnapshot(snap => {
        let arr = [];
        snap.forEach(doc => {
          var obj = doc.data();
          obj.id = doc.id;
          obj.searchType = "audio";
          arr.push(obj);
        });
        dispatch(audios(arr));
        dispatch({
          audiosLoading: false,
          type: "AUDIOS_LOADING",
        })
      });
  }
}

export const audios = audios => {
  return {
    type: "AUDIOS",
    audios
  };
};

export const getBooks = () => {
  return (dispatch) => {
    FIRESTORE.collection("books")
      .orderBy("timeStamp", "desc")
      .onSnapshot(snap => {
        let arr = [];
        snap.forEach(doc => {
          var obj = doc.data();
          obj.id = doc.id;
          obj.searchType = "book";
          arr.push(obj);
        });
        dispatch(books(arr));
        dispatch({
          booksLoading: false,
          type: "BOOKS_LOADING",
        })
      });
  }
}

export const books = books => {
  return {
    type: "BOOKS",
    books
  };
};

export const getEvents = () => {
  return (dispatch) => {
    FIRESTORE.collection("events")
      .orderBy("timeStamp", "asc")
      .onSnapshot(snap => {
        let arr = [];
        snap.forEach(doc => {
          var obj = doc.data();
          obj.id = doc.id;
          obj.searchType = "event";
          arr.push(obj);
          dispatch({
            eventsLoading: false,
            type: "EVENTS_LOADING",
          })
        });
        dispatch(events(arr));
      });
  }
}

export const events = events => {
  return {
    type: "EVENTS",
    events
  };
};

export const getPersonalities = () => {
  return (dispatch) => {
    FIRESTORE.collection("personalities")
      .orderBy("timeStamp", "asc")
      .onSnapshot(snap => {
        let arr = [];
        snap.forEach(doc => {
          var obj = doc.data();
          obj.id = doc.id;
          obj.searchType = "personality";
          arr.push(obj);
        });
        dispatch(personalities(arr));
      });
  }
}

export const personalities = personalities => {
  return {
    type: "PERSONALITIES",
    personalities
  };
};

export const getSliders = () => {
  return (dispatch) => {
    FIRESTORE.collection("slider").onSnapshot(snap => {
      let arr = [];
      snap.forEach(doc => {
        var obj = doc.data();
        obj.id = doc.id;
        arr.push(obj);
      });
      dispatch(sliders(arr));
    });
  }
}

export const sliders = sliders => {
  return {
    type: "SLIDERS",
    sliders
  };
};

export const getSliderGallery = () => {
  return (dispatch) => {
    FIRESTORE.collection("sliderGallery")
      .orderBy("timeStamp", "asc")
      .onSnapshot(snap => {
        let arr = [];
        snap.forEach(doc => {
          var obj = doc.data();
          obj.id = doc.id;
          arr.push(obj);
        });
        dispatch(sliderGallery(arr));
      });
  }
}

export const sliderGallery = sliderGallery => {
  return {
    type: "SLIDER_GALLERY",
    sliderGallery
  };
};

// Details

export const bookDetails = bookDetails => {
  return {
    type: "GET_BOOK_DETAILS",
    bookDetails
  };
};

export const audioDetails = audioDetails => {
  return {
    type: "GET_AUDIO_DETAILS",
    audioDetails
  };
};

export const personalityDetails = personalityDetails => {
  return {
    type: "GET_PERSONALITY_DETAILS",
    personalityDetails
  };
};

export const eventDetails = eventDetails => {
  return {
    type: "GET_EVENT_DETAILS",
    eventDetails
  };
};
